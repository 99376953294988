<template>
  <div
    class="fill-height"
  >
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-img
            :src="require('@/assets/images/logo-odevia.png')"
            alt=""
            contain
            min-height="47"
          />

          <div class="mt-2 primary--text font-italic font-weight-light text-center">
            {{ $parameter('CUSTOMER_NAME') }}
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item
        v-for="(value, name) in displayItems"
        :key="`display-${name}`"
      >
        <v-list-item-icon>
          <v-icon
            v-text="icons[name]"
          />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t('messages.views.public.dashboard.drawer.items.' + name) }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <o-switch
            v-model="displayItems[name]"
            dense
            hide-details
          />
        </v-list-item-action>
      </v-list-item>

      <v-divider />

      <v-list-item
        v-if="!isAuthenticated"
        :to="{ name: 'auth_login' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('messages.views.public.dashboard.drawer.items.login') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-else
        :to="{ name: 'dashboard' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-lock</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('messages.views.public.dashboard.drawer.items.admin') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-slot:append>
        <v-btn
          block
          text
          class="text--secondary font-italic"
          href="https://odeven.fr"
          target="_blank"
        >
          {{ $t('messages.views.public.dashboard.drawer.items.poweredByOdeven') }}
        </v-btn>
      </template>
    </v-navigation-drawer>

    <o-map ref="map" />

    <v-container
      fluid
      class="map-tools fill-height"
    >
      <v-row
        no-gutters
        class="fill-height"
      >
        <v-col
          cols="2"
          class="d-flex fill-height flex-column align-start"
        >
          <v-btn
            icon
            @click.stop="drawer = !drawer"
          >
            <v-icon>
              mdi-menu
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
          cols="8"
          class="d-flex flex-column justify-end"
        >
          <o-card-module
            v-if="displayItems.informations && nonGeolocatedItems && nonGeolocatedItems.length"
            :loading="loading.items"
            class="mb-n3"
            :disable-hover="resizingItems"
          >
            <items
              :loading="loading.items"
              :items="nonGeolocatedItems"
              :categories="categories"
              @item-click="(itemId) => selectedItems.itemId = itemId"
              @resize="(resizing) => resizingItems = resizing"
            />
          </o-card-module>
        </v-col>

        <v-col
          cols="2"
          class="d-flex fill-height flex-column align-end"
        >
          <div
            v-if="displayItems.informations && categories && categories.length"
          >
            <categories
              :loading="loading.categories"
              :categories="categories"
              :hidden-categories="hiddenCategories"
              @toggle-category="toggleCategory"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <item-modal
      v-if="displayItems.informations && selectedItems.itemId"
      :item-id="selectedItems.itemId"
      @close="selectedItems.itemId = null"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    components: {
      Items: () => import('@/views/public/components/dashboard/Items'),
      Categories: () => import('@/views/public/components/dashboard/Categories'),
      ItemModal: () => import('@/views/public/components/dashboard/ItemModal'),
    },

    apollo: {
      sections: {
        query: require('@gql/views/public/dashboard/getSections.gql'),
        client: 'floating-traffic-data',
        fetchPolicy: 'no-cache',
        skip: function () {
          return !this.$parameter('TRAFFIC_DATA_ENABLE') || !this.$parameter('PUBLIC_DASHBOARD_DISPLAY_TRAFFIC');
        },
        result (res, key) {
          this.loading.sections = res.loading;
        },
        update: function (data) {
          return data.getSections;
        },
        error: function () {
          this.$flash('errors.apollo.query.traffic', 'error');
        },
      },
      informations: {
        query: require('@gql/views/public/dashboard/getData.gql'),
        client: 'information',
        fetchPolicy: 'no-cache',
        skip: function () {
          return !this.$parameter('PUBLIC_DASHBOARD_DISPLAY_INFORMATIONS');
        },
        variables: function () {
          return {
            date: moment().utc().format('Y-MM-DD HH:mm:ss'),
          };
        },
        result (res, key) {
          this.loading.items = res.loading;
          this.loading.categories = res.loading;
        },
        update (data) {
          this.categories = data.getCategories;
          this.items = data.searchItems.edges.map(edge => edge.node);
        },
        error: function () {
          this.$flash('errors.apollo.query.informations', 'error');
        },
      },
    },

    data: function () {
      return {
        loading: {
          items: true,
          categories: true,
        },
        icons: {
          traffic: 'mdi-car-multiple',
          informations: 'mdi-bullhorn',
        },
        drawer: false,
        categories: [],
        resizingItems: false,
        hiddenCategories: [],
        selectedItems: {
          itemId: null,
        },
        featureTypes: ['itemMarker', 'itemCircle', 'itemPolygon'],
        items: null,
        displayItems: {
          traffic: this.$parameter('TRAFFIC_DATA_ENABLE') && this.$parameter('PUBLIC_DASHBOARD_DISPLAY_TRAFFIC'),
          informations: this.$parameter('PUBLIC_DASHBOARD_DISPLAY_INFORMATIONS'),
        },
      };
    },

    computed: {
      ...mapGetters('auth', ['isAuthenticated']),

      nonGeolocatedItems () {
        const items = [];

        if (this.items) {
          for (const item of this.items) {
            if (!this.hiddenCategories.includes(item.categoryId)) {
              let hasShapes = false;
              for (const type of Object.keys(item.shapes)) {
                if (item.shapes[type].length) {
                  hasShapes = true;
                  break;
                }
              }

              if (!hasShapes) {
                items.push(item);
              }
            }
          }
        }

        return items;
      },
    },

    watch: {
      sections () {
        this.refreshSectionFeatures();
      },
      items () {
        this.refreshItemFeatures();
      },
      'displayItems.traffic': function () {
        this.refreshSectionFeatures();
      },
      'displayItems.informations': function () {
        this.refreshItemFeatures();
      },
    },

    mounted () {
      this.$store.commit('map/reset');
    },

    created () {
      this.$bus.$on('map.feature.click', this.onFeatureClick);
    },

    destroyed () {
      this.$bus.$off('map.feature.click', this.onFeatureClick);
    },

    methods: {
      getCategory (categoryId) {
        return this.categories.find((category) => category.id === categoryId);
      },

      refreshItemFeatures () {
        if (this.items) {
          if (this.displayItems.informations) {
            const items = this.items.filter((item) => !this.hiddenCategories.includes(item.categoryId));
            const features = this.getFeaturesFromItems(items);
            for (const type of Object.keys(features)) {
              this.$store.commit('map/setFeatures', {
                type: type,
                features: features[type],
              });
            }
          } else {
            for (const type of this.featureTypes) {
              this.$store.commit('map/removeAllFeatures', {
                type: type,
              });
            }
          }
        }
      },

      refreshSectionFeatures () {
        if (this.sections) {
          if (this.displayItems.traffic) {
            this.$store.commit('map/setFeatures', {
              type: 'section',
              features: this.sections,
              extraProperties: {
                clickable: false,
                selectable: false,
                draggable: false,
              },
            });
          } else {
            this.$store.commit('map/removeAllFeatures', {
              type: 'section',
            });
          }
        }
      },

      getFeaturesFromItems (items) {
        const markers = [];
        for (const item of items) {
          for (const i in item.shapes.markers) {
            const marker = item.shapes.markers[i];

            markers.push({
              id: item.id + '-' + i,
              icon: 'mdi-' + (item.icon ? item.icon : this.getCategory(item.categoryId).icon),
              color: '#' + (item.color ? item.color : this.getCategory(item.categoryId).color),
              location: marker,
            });
          }
        }

        const circles = [];
        for (const item of items) {
          for (const i in item.shapes.circles) {
            const circle = item.shapes.circles[i];

            circles.push({
              id: item.id + '-' + i,
              color: '#' + (item.color ? item.color : this.getCategory(item.categoryId).color),
              center: circle.center,
              radius: circle.radius,
            });
          }
        }

        const polygons = [];
        for (const item of items) {
          for (const i in item.shapes.polygons) {
            const polygon = item.shapes.polygons[i];

            polygons.push({
              id: item.id + '-' + i,
              color: '#' + (item.color ? item.color : this.getCategory(item.categoryId).color),
              points: polygon.points,
            });
          }
        }

        return {
          itemMarker: markers,
          itemCircle: circles,
          itemPolygon: polygons,
        };
      },

      toggleCategory (categoryId) {
        const index = this.hiddenCategories.indexOf(categoryId);
        if (index !== -1) {
          this.hiddenCategories.splice(index, 1);
        } else {
          this.hiddenCategories.push(categoryId);
        }

        this.refreshItemFeatures();
      },

      onFeatureClick (type, id) {
        switch (type) {
          case 'itemMarker':
          case 'itemCircle':
          case 'itemPolygon':
            const parts = id.split('-');
            this.selectedItems.itemId = parseInt(parts[0]);
        }
      },
    },
  };
</script>

<style scoped lang="sass">
  .map-tools
    pointer-events: none
    position: absolute
    top: 0
    left: 0

    .col *
      pointer-events: initial
</style>
